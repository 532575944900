
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import NonGAHistory from "@/components/nonga/NonGAHistory.vue";

import BalanceDetails from "@/components/cards/BalanceDetails.vue";

export default defineComponent({
  name: "nonga",
  components: {
    NonGAHistory,
    BalanceDetails
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
     // tabIndex.value = parseInt(localStorage.getItem("nongaTab") || "0");
      //setCurrentPageTitle("Non-GA");

    });
    
  }
});
