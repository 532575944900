
import { defineComponent } from "vue";
import {getNonGATree1}  from "@/api/nonga";
import ECP_EDP_DashboardNavigator from "@/components/cards/ECP_EDP_DashboardNavigator.vue"

export default defineComponent({
  name: "stairstephistory", 
  methods: {
    async getThisMonthAndYear(){
      this.loading = true;
      var d = new Date();
      var m = d.getMonth();
      var y = d.getFullYear();
      this.month = m + 1;
      this.year = y;
      this.loading = false;
    },  
    async getNonGATree_1(){
      this.loading = true;
      var params = {
        "month" : this.month,
        "year" : this.year
      }
    const response = await getNonGATree1(params);
    this.list = response.data.pageList;
    this.loading = false;
    },
  },
  mounted(){
      this.getThisMonthAndYear();
      this.getNonGATree_1();
  },
  data(){
      return {
        month:0,
        year: 0,
        list : [],
        loading: true,
      }
  },
  components: {
    ECP_EDP_DashboardNavigator
  },
  props: {
    widgetClasses: String
  }
});

